/**
 * Sets values of nested properties
 * Ex. modernThemeSettings.sections.banner.display
 */
export const setNestedProperty = (obj: any, path: string, value: any) => {
  const keys = path.split(".");
  let current = obj;

  for (let i = 0; i < keys.length - 1; i++) {
    const key = keys[i];
    if (!current[key]) {
      current[key] = {};
    }
    current = current[key];
  }

  current[keys[keys.length - 1]] = value;
};
