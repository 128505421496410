import { CSSProperties } from "react";
import styled from "styled-components";
import { Box } from "@shopify/polaris";

export const MobilePreview = styled(Box)`
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 375px;
  height: 90vh;
  bgcolor: #f6f6f7;
  border: 1px solid #c9cccf;
  borderradius: 8px;
  borderbottomleftradius: 0px;
  borderbottomrightradius: 0px;
  boxshadow: 24;
  overflow: scroll;
`;

export const DesktopPreview = styled(Box)`
  position: absolute;
  top: 52%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: 95vh;
  bgcolor: #f6f6f7;
  boxshadow: 24;
  overflow: scroll;
`;

export const codeMirrorContainerStyle: CSSProperties = {
  height: 550,
  width: "inherit",
  maxWidth: 490,
  overflowY: "auto",
  borderRadius: "8px",
  boxShadow: "0px 2px 1px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.25)",
};

export enum ThemeSettingsTabs {
  GLOBAL = "global",
  PRODUCT = "product",
}

export const TabOptions = [
  {
    id: ThemeSettingsTabs.GLOBAL,
    content: "Portal Settings",
  },
  {
    id: ThemeSettingsTabs.PRODUCT,
    content: "Advanced",
  },
];
